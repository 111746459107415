import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getTenantDomain } from "../../helper";
import { useParams } from 'react-router-dom';

// A stable debouncing hook
const useDebounce = (func, delay) => {
  const funcRef = useRef(func);
  const timeoutRef = useRef(null);

  // Update the ref if func changes
  useEffect(() => {
    funcRef.current = func;
  }, [func]);

  // Return a stable debounced function
  const debouncedFunc = useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      funcRef.current(...args);
    }, delay);
  }, [delay]);

  return debouncedFunc;
};

const VoucherInput = ({ voucherCode, setVoucherCode, setVoucherData }) => {
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const params = useParams();
  const abortControllerRef = useRef(null);

  // Wrap validateVoucher in useCallback so it doesn't change every render
  const validateVoucher = useCallback(
    async (code) => {
      if (!code) return;

      // Abort any ongoing request before starting a new one
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      setIsLoading(true);
      try {
        const response = await fetch(
          `${getTenantDomain(params.tenant)}/api/iframe/verify-voucher/${code}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ voucher_code: code, propertyId: params.id }),
            signal: abortControllerRef.current.signal,
          }
        );
        const data = await response.json();
        setMessage(data.message || '');
        setIsValid(data.valid);
        setVoucherData(data.valid ? data.coupon : null);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setMessage('Error validating voucher.');
          setIsValid(false);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [params.id, params.tenant, setVoucherData]
  );

  const debouncedValidate = useDebounce(validateVoucher, 500);

  useEffect(() => {
    if (voucherCode.trim().length > 0) {
      // Only run debounced validation when voucherCode changes
      debouncedValidate(voucherCode);
    } else {
      setMessage('');
      setIsValid(true);
    }
  }, [voucherCode, debouncedValidate]);

  const handleVoucherChange = (e) => {
    setVoucherCode(e.target.value);
  };

  const inputClass = `formControl ${!isValid ? 'invalid-input' : ''}`;
  const inputClassMessage = `message ${!isValid ? 'invalid-input' : ''}`;

  return (
    <form className="bookingForm">
      <div className="colsHolder">
        <div className="chCol chCol12">
          <div className="formGroup">
            <input
              type="text"
              className={inputClass}
              placeholder="Enter voucher code"
              value={voucherCode}
              onChange={handleVoucherChange}
              ref={inputRef}
            />
            {isLoading && <div className="loading-spinner">Validating...</div>}
            {message && <div className={inputClassMessage}>{message}</div>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default VoucherInput;
